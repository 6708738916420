<template>
  <v-main>
    <v-container>
      <v-row dense>
        <v-col class="col-sm-6 offset-sm-3">
          <div class="mb-2 d-flex justify-space-between">
            <BackLink />
            <h2 class="text-h5 grey--text text--darken-2">Setting</h2>
          </div>
          <v-form @submit="submit">
            <v-expansion-panels
              v-model="panel"
              class="mb-4"
              mandatory
              multiple
              accordion
              flat
              tile
            >
              <v-expansion-panel>
                <v-expansion-panel-header>
                  General
                  <template v-slot:actions>
                    <v-avatar
                      color="#00cccc"
                      size="16"
                      max-width="16"
                      max-height="16"
                      tile
                      class="col-2 pa-0 ml-auto"
                    >
                      <v-icon small color="white">mdi-menu-down</v-icon>
                    </v-avatar>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <input-field
                    v-model="data.app_name"
                    text
                    label="Nama RS"
                    :uppercase="false"
                  />
                  <input-field
                    v-model="data.unit"
                    text
                    label="Unit"
                    :uppercase="false"
                  />
                  <input-field
                    v-model="data.server_url"
                    text
                    label="Server URL"
                    :uppercase="false"
                  />
                  <v-switch
                    v-model="data.print_server"
                    :label="`Print Server: ${
                      data.print_server ? 'Enable' : 'Disable'
                    }`"
                    dense
                  />
                  <v-switch
                    v-model="data.gtm"
                    :label="`Tracking: ${data.gtm ? 'Enable' : 'Disable'}`"
                    dense
                  />
                  <v-switch
                    v-model="data.print_portable"
                    :label="`Print Portable: ${
                      data.print_portable ? 'Enable' : 'Disable'
                    }`"
                    dense
                  />
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  Form
                  <template v-slot:actions>
                    <v-avatar
                      color="#00cccc"
                      size="16"
                      max-width="16"
                      max-height="16"
                      tile
                      class="col-2 pa-0 ml-auto"
                    >
                      <v-icon small color="white">mdi-menu-down</v-icon>
                    </v-avatar>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <input-field
                    class="mb-3"
                    v-model="data.defaultData"
                    select
                    :itemSelect="['pasien', 'inventaris', 'staff']"
                    label="Default Data"
                  />
                  <v-expansion-panels class="mb-2">
                    <draggable
                      class="layer-list"
                      tag="div"
                      v-model="form"
                      v-bind="dragOptions"
                      :sort="false"
                      @start="drag = true"
                      @end="drag = false"
                      style="width: 100%"
                      handle=".handle"
                    >
                      <transition-group
                        type="transition"
                        :name="!drag ? 'flip-list' : null"
                      >
                        <v-expansion-panel
                          v-for="(item, i) in form"
                          :key="i + 1"
                        >
                          <v-expansion-panel-header class="text-subtitle-1">
                            <div class="d-flex">
                              <v-icon class="handle mr-2" small
                                >mdi-cursor-move</v-icon
                              >
                              <v-icon
                                class="mr-2"
                                small
                                @click.stop="form.splice(i, 1)"
                                >mdi-close-circle</v-icon
                              >
                              {{ item.label }}
                            </div>
                            <template v-slot:actions>
                              <v-avatar
                                color="#00cccc"
                                size="16"
                                max-width="16"
                                max-height="16"
                                tile
                                class="col-2 pa-0 ml-auto"
                              >
                                <v-icon small color="white"
                                  >mdi-menu-down</v-icon
                                >
                              </v-avatar>
                            </template>
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <input-field
                              v-model="item.label"
                              text
                              label="Label"
                              :uppercase="false"
                            />
                            <input-field
                              v-model="item.model"
                              text
                              label="Model"
                              :uppercase="false"
                            />
                            <input-field
                              v-model="item.hint"
                              text
                              label="Hint"
                              :uppercase="false"
                            />
                            <input-field
                              v-model="item.type"
                              select
                              :itemSelect="[
                                'text',
                                'select',
                                'autocomplete',
                                'textarea',
                                'upload',
                                'date',
                                'number',
                                'custom',
                                'hidden',
                              ]"
                              label="Type"
                              :uppercase="false"
                            />
                            <input-field
                              v-if="
                                item.type === 'select' ||
                                item.type === 'autocomplete'
                              "
                              v-model="item.items"
                              text
                              label="Items"
                              :uppercase="false"
                            />
                            <input-field
                              v-if="
                                item.type === 'select' ||
                                item.type === 'autocomplete'
                              "
                              v-model="item.multiple"
                              select
                              label="Multiple"
                              :uppercase="false"
                              :itemSelect="[true, false]"
                            />
                            <template v-else-if="item.type === 'upload'">
                              <input-field
                                v-model="item.width"
                                text
                                label="Width"
                                :uppercase="false"
                              />
                              <input-field
                                v-model="item.height"
                                text
                                label="height"
                                :uppercase="false"
                              />
                            </template>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </transition-group>
                    </draggable>
                  </v-expansion-panels>
                  <div class="text-center">
                    <v-btn
                      depressed
                      dark
                      small
                      color="primary"
                      @click="addForm()"
                    >
                      Tambah Form
                    </v-btn>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  Resep
                  <template v-slot:actions>
                    <v-avatar
                      color="#00cccc"
                      size="16"
                      max-width="16"
                      max-height="16"
                      tile
                      class="col-2 pa-0 ml-auto"
                    >
                      <v-icon small color="white">mdi-menu-down</v-icon>
                    </v-avatar>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-switch
                    class="text-normal"
                    false-value="disable"
                    true-value="enable"
                    v-model="resep.enable"
                    :label="`Resep: ${resep.enable}`"
                    color=""
                    dense
                  />
                  <input-field
                    v-model="resep.aturan"
                    text
                    label="Aturan Penggunaan"
                  />
                  <input-field
                    v-model="resep.waktu"
                    text
                    label="Waktu Penggunaan"
                  />
                  <input-field v-model="resep.catatan" text label="Catatan" />
                  <input-field
                    v-model="resep.satuan"
                    text
                    label="Default Satuan"
                  />
                  <input-field
                    v-model="resep.enable_tipe"
                    select
                    multiple
                    :itemSelect="[
                      { text: 'IP Oral', value: 'etiket_putih' },
                      { text: 'IP Luar', value: 'etiket_biru' },
                      { text: 'Racikan', value: 'racikan' },
                      { text: 'Racikan Biru', value: 'racikan_biru' },
                      { text: 'UDD', value: 'udd' },
                      { text: 'UDD Biru', value: 'udd_biru' },
                      { text: 'Infus', value: 'infus' },
                      { text: 'Injeksi', value: 'injeksi' },
                    ]"
                    label="Enable Tipe"
                    :clearable="false"
                    tipe
                  />
                  <input-field
                    v-model="resep.tipe"
                    select
                    :itemSelect="[
                      { text: 'IP Oral', value: 'etiket_putih' },
                      { text: 'IP Luar', value: 'etiket_biru' },
                      { text: 'UDD', value: 'udd' },
                      { text: 'UDD Biru', value: 'udd_biru' },
                      { text: 'Infus', value: 'infus' },
                      { text: 'Injeksi', value: 'injeksi' },
                    ]"
                    label="Default Tipe"
                    :clearable="false"
                    tipe
                  />
                  <input-field v-model="resep.qty" text label="Default Qty" />
                  <input-field
                    v-model="resep.udd_by"
                    select
                    :itemSelect="[
                      { text: 'Default', value: 'default' },
                      { text: 'Waktu', value: 'waktu' },
                      { text: 'Waktu Split', value: 'waktu_split' },
                    ]"
                    label="Default UDD"
                    :clearable="false"
                  />
                  <v-switch
                    class="text-normal"
                    v-model="resep.waktu_fleksibel"
                    label="Waktu Fleksibel"
                    color=""
                    dense
                  />
                  <v-card v-if="!resep.waktu_fleksibel" class="mx-auto my-2">
                    <v-card-actions class="pa-1">
                      <v-btn text @click="show = !show">UDD Putih</v-btn>

                      <v-spacer></v-spacer>

                      <v-btn icon @click="show = !show">
                        <v-icon>{{
                          show ? "mdi-chevron-up" : "mdi-chevron-down"
                        }}</v-icon>
                      </v-btn>
                    </v-card-actions>

                    <v-expand-transition>
                      <v-card-text v-show="show">
                        <v-expansion-panels class="mb-2">
                          <draggable
                            class="layer-list"
                            tag="div"
                            v-model="waktu_udd"
                            v-bind="dragOptions"
                            :sort="false"
                            @start="drag = true"
                            @end="drag = false"
                            style="width: 100%"
                            handle=".handle"
                          >
                            <transition-group
                              type="transition"
                              :name="!drag ? 'flip-list' : null"
                            >
                              <v-expansion-panel
                                v-for="(item, i) in waktu_udd"
                                :key="i + 1"
                              >
                                <v-expansion-panel-header
                                  class="text-subtitle-1"
                                >
                                  <div class="d-flex">
                                    <v-icon class="handle mr-2" small
                                      >mdi-cursor-move</v-icon
                                    >
                                    <v-icon
                                      class="mr-2"
                                      small
                                      @click.stop="waktu_udd.splice(i, 1)"
                                      >mdi-close-circle</v-icon
                                    >
                                    {{ item.waktu }}
                                  </div>
                                  <template v-slot:actions>
                                    <v-avatar
                                      color="#00cccc"
                                      size="16"
                                      max-width="16"
                                      max-height="16"
                                      tile
                                      class="col-2 pa-0 ml-auto"
                                    >
                                      <v-icon small color="white"
                                        >mdi-menu-down</v-icon
                                      >
                                    </v-avatar>
                                  </template>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                  <input-field
                                    v-model="item.waktu"
                                    text
                                    label="Waktu Pemberian"
                                    :uppercase="false"
                                  />
                                  <input-field
                                    v-model="item.dosis"
                                    text
                                    label="Dosis"
                                    :uppercase="false"
                                  />
                                </v-expansion-panel-content>
                              </v-expansion-panel>
                            </transition-group>

                            <div slot="footer" class="text-center">
                              <v-btn
                                depressed
                                dark
                                small
                                color="primary"
                                @click="addWaktu('putih')"
                              >
                                Tambah Waktu
                              </v-btn>
                            </div>
                          </draggable>
                        </v-expansion-panels>
                      </v-card-text>
                    </v-expand-transition>
                  </v-card>

                  <v-card v-if="!resep.waktu_fleksibel" class="mx-auto my-2">
                    <v-card-actions class="pa-1">
                      <v-btn text @click="show2 = !show2">UDD Biru</v-btn>

                      <v-spacer></v-spacer>

                      <v-btn icon @click="show2 = !show2">
                        <v-icon>{{
                          show2 ? "mdi-chevron-up" : "mdi-chevron-down"
                        }}</v-icon>
                      </v-btn>
                    </v-card-actions>

                    <v-expand-transition>
                      <v-card-text v-show="show2">
                        <v-expansion-panels class="mb-2">
                          <draggable
                            class="layer-list"
                            tag="div"
                            v-model="waktu_udd_biru"
                            v-bind="dragOptions"
                            :sort="false"
                            @start="drag = true"
                            @end="drag = false"
                            style="width: 100%"
                            handle=".handle"
                          >
                            <transition-group
                              type="transition"
                              :name="!drag ? 'flip-list' : null"
                            >
                              <v-expansion-panel
                                v-for="(item, i) in waktu_udd_biru"
                                :key="i + 1"
                              >
                                <v-expansion-panel-header
                                  class="text-subtitle-1"
                                >
                                  <div class="d-flex">
                                    <v-icon class="handle mr-2" small
                                      >mdi-cursor-move</v-icon
                                    >
                                    <v-icon
                                      class="mr-2"
                                      small
                                      @click.stop="waktu_udd_biru.splice(i, 1)"
                                      >mdi-close-circle</v-icon
                                    >
                                    {{ item.waktu }}
                                  </div>
                                  <template v-slot:actions>
                                    <v-avatar
                                      color="#00cccc"
                                      size="16"
                                      max-width="16"
                                      max-height="16"
                                      tile
                                      class="col-2 pa-0 ml-auto"
                                    >
                                      <v-icon small color="white"
                                        >mdi-menu-down</v-icon
                                      >
                                    </v-avatar>
                                  </template>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                  <input-field
                                    v-model="item.waktu"
                                    text
                                    label="Waktu Pemberian"
                                    :uppercase="false"
                                  />
                                  <input-field
                                    v-model="item.dosis"
                                    text
                                    label="Dosis"
                                    :uppercase="false"
                                  />
                                </v-expansion-panel-content>
                              </v-expansion-panel>
                            </transition-group>

                            <div slot="footer" class="text-center">
                              <v-btn
                                depressed
                                dark
                                small
                                color="primary"
                                @click="addWaktu('biru')"
                              >
                                Tambah Waktu
                              </v-btn>
                            </div>
                          </draggable>
                        </v-expansion-panels>
                      </v-card-text>
                    </v-expand-transition>
                  </v-card>

                  <v-switch
                    class="text-normal"
                    v-model="resep.signa_format"
                    label="Signa Format"
                    color=""
                    dense
                  />
                  <v-card v-if="resep.signa_format" class="mx-auto my-2">
                    <v-card-actions class="pa-1">
                      <v-btn text @click="show3 = !show3">List Signa</v-btn>

                      <v-spacer></v-spacer>

                      <v-btn icon @click="show3 = !show3">
                        <v-icon>{{
                          show3 ? "mdi-chevron-up" : "mdi-chevron-down"
                        }}</v-icon>
                      </v-btn>
                    </v-card-actions>

                    <v-expand-transition>
                      <v-card-text v-show="show3">
                        <v-expansion-panels class="mb-2">
                          <draggable
                            class="layer-list"
                            tag="div"
                            v-model="signa"
                            v-bind="dragOptions"
                            :sort="false"
                            @start="drag = true"
                            @end="drag = false"
                            style="width: 100%"
                            handle=".handle"
                          >
                            <transition-group
                              type="transition"
                              :name="!drag ? 'flip-list' : null"
                            >
                              <v-expansion-panel
                                v-for="(item, i) in signa"
                                :key="i + 1"
                              >
                                <v-expansion-panel-header
                                  class="text-subtitle-1"
                                >
                                  <div class="d-flex">
                                    <v-icon class="handle mr-2" small
                                      >mdi-cursor-move</v-icon
                                    >
                                    <v-icon
                                      class="mr-2"
                                      small
                                      @click.stop="signa.splice(i, 1)"
                                      >mdi-close-circle</v-icon
                                    >
                                    {{ item.aturan }}
                                  </div>
                                  <template v-slot:actions>
                                    <v-avatar
                                      color="#00cccc"
                                      size="16"
                                      max-width="16"
                                      max-height="16"
                                      tile
                                      class="col-2 pa-0 ml-auto"
                                    >
                                      <v-icon small color="white"
                                        >mdi-menu-down</v-icon
                                      >
                                    </v-avatar>
                                  </template>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                  <input-field
                                    v-model="item.aturan"
                                    text
                                    label="Aturan"
                                    :uppercase="false"
                                  />
                                  <input-field
                                    v-model="item.waktu"
                                    text
                                    label="Waktu"
                                    :uppercase="false"
                                  />
                                  <input-field
                                    v-model="item.format"
                                    text
                                    label="Format"
                                    :uppercase="false"
                                  />
                                </v-expansion-panel-content>
                              </v-expansion-panel>
                            </transition-group>

                            <div slot="footer" class="text-center">
                              <v-btn
                                depressed
                                dark
                                small
                                color="primary"
                                @click="addSigna()"
                              >
                                Tambah Signa
                              </v-btn>
                            </div>
                          </draggable>
                        </v-expansion-panels>
                      </v-card-text>
                    </v-expand-transition>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  API
                  <template v-slot:actions>
                    <v-avatar
                      color="#00cccc"
                      size="16"
                      max-width="16"
                      max-height="16"
                      tile
                      class="col-2 pa-0 ml-auto"
                    >
                      <v-icon small color="white">mdi-menu-down</v-icon>
                    </v-avatar>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-switch
                    class="text-normal"
                    v-model="data.api.enable"
                    :label="`API Pasien: ${data.api.enable}`"
                    color=""
                    dense
                  />
                  <input-field
                    v-model="data.api.trigger"
                    select
                    :itemSelect="form"
                    itemText="model"
                    :uppercase="false"
                    label="Trigger Input"
                  />
                  <v-switch
                    class="text-normal"
                    v-model="data.apiFarmasi.enable"
                    :label="`API Farmasi: ${data.apiFarmasi.enable}`"
                    color=""
                    dense
                  />
                  <input-field
                    v-model="data.apiFarmasi.trigger"
                    select
                    :itemSelect="form"
                    itemText="model"
                    :uppercase="false"
                    label="Trigger Input"
                  />
                  <input-field
                    v-model="data.apiFarmasi.data"
                    select
                    multiple
                    :itemSelect="form"
                    itemText="model"
                    :uppercase="false"
                    label="Data"
                  />
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  Pengaturan Lain
                  <template v-slot:actions>
                    <v-avatar
                      color="#00cccc"
                      size="16"
                      max-width="16"
                      max-height="16"
                      tile
                      class="col-2 pa-0 ml-auto"
                    >
                      <v-icon small color="white">mdi-menu-down</v-icon>
                    </v-avatar>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-switch
                    class="text-normal"
                    v-model="data.antrian"
                    :label="`Antrian: ${data.antrian}`"
                    color=""
                    dense
                  ></v-switch>
                  <v-switch
                    class="text-normal"
                    v-model="data.filter.enable"
                    :label="`Filter: ${data.filter.enable}`"
                    color=""
                    dense
                  />
                  <input-field
                    v-model="data.filter.trigger"
                    select
                    :itemSelect="form"
                    itemText="model"
                    :uppercase="false"
                    label="Trigger Filter"
                  />
                  <v-switch
                    class="text-normal"
                    v-model="data.gizi"
                    :label="`Input Gizi: ${data.gizi}`"
                    color=""
                    dense
                  />
                  <v-switch
                    class="text-normal"
                    v-model="data.custom.enable"
                    :label="`Custom: ${data.custom.enable}`"
                    color=""
                    dense
                  ></v-switch>
                  <input-field
                    v-if="data.custom.enable === true"
                    v-model="data.custom.label"
                    text
                    label="Label"
                  />
                  <input-field
                    v-if="data.custom.enable === true"
                    v-model="data.custom.icon"
                    text
                    label="ICON"
                  />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <v-card-actions class="px-0">
              <v-btn
                block
                depressed
                :loading="isLoading"
                color="#00cccc"
                class="white--text"
                @click="submit"
              >
                SIMPAN
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import InputField from "@/components/InputField";
import draggable from "vuedraggable";

export default {
  name: "Setting",
  components: { InputField, draggable },
  data() {
    return {
      isLoading: false,
      panel: [],
      form: this.$store.state.settings.form || [],
      waktu_udd: this.$store.state.settings.resep.waktu_udd || [],
      waktu_udd_biru: this.$store.state.settings.resep.waktu_udd_biru || [],
      signa: this.$store.state.settings.resep.signa || [],
      drag: false,
      show: false,
      show2: false,
      show3: false,
    };
  },
  computed: {
    data: {
      get() {
        return this.$store.state.settings ? this.$store.state.settings : {};
      },
      set(val) {
        this.$store.commit("basic", { key: "settings", value: val });
      },
    },
    resep: {
      get() {
        return this.$store.state.settings.resep
          ? this.$store.state.settings.resep
          : {};
      },
      set(val) {
        this.$store.commit("setting", { key: "resep", value: val });
      },
    },
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },
  methods: {
    getFormObject() {
      return {
        model: "input",
        label: "Input Baru",
        hint: null,
        type: "text",
        items: null,
      };
    },
    addForm() {
      this.form.push(this.getFormObject());
    },
    addWaktu(tipe) {
      if (tipe === "putih") {
        this.waktu_udd.push({ waktu: "Pagi 07:00", dosis: null });
      } else {
        this.waktu_udd_biru.push({ waktu: "Pagi 07:00", dosis: null });
      }
    },
    addSigna() {
      this.signa.push({
        aturan: "3 x Oral",
        waktu: "06:00 - 14:00 - 22:00",
        format: "{aturan} {dosis} {keterangan}",
      });
    },
    submit() {
      this.resep.waktu_udd = this.waktu_udd;
      this.resep.waktu_udd_biru = this.waktu_udd_biru;
      this.resep.signa = this.signa;
      this.data.form = this.form;
      this.data.resep = this.resep;

      this.$store.commit("basic", { key: "settings", value: this.data });
      this.$snackbar({
        text: "Setting berhasil disimpan",
      });
    },
  },
};
</script>
